import React from "react";
import Diamondeducation from "../components/Diamondeducation"
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const Certified = (props) => {
    return (
        <Layout>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <Diamondeducation {...props} fields={props?.data}/>
        </Layout>
    )
};
const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(Certified);

export const query = graphql`
fragment CertifiedFragment on MarkdownRemark {
      frontmatter {
      title
      description
      img{
         publicURL
      }
    }
  }
  query CertifiedPageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "certified-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enCertified: markdownRemark(frontmatter: {templateKey: {eq: "en-certified"}}) {
    ...CertifiedFragment
  }
    deCertified: markdownRemark(frontmatter: {templateKey: {eq: "de-certified"}}) {
    ...CertifiedFragment
  }
    esCertified: markdownRemark(frontmatter: {templateKey: {eq: "es-certified"}}) {
    ...CertifiedFragment
  }
    frCertified: markdownRemark(frontmatter: {templateKey: {eq: "fr-certified"}}) {
    ...CertifiedFragment
  }
    heCertified: markdownRemark(frontmatter: {templateKey: {eq: "he-certified"}}) {
    ...CertifiedFragment
  }
    itCertified: markdownRemark(frontmatter: {templateKey: {eq: "it-certified"}}) {
    ...CertifiedFragment
  }
    jaCertified: markdownRemark(frontmatter: {templateKey: {eq: "ja-certified"}}) {
    ...CertifiedFragment
  }
    zhCertified: markdownRemark(frontmatter: {templateKey: {eq: "zh-certified"}}) {
    ...CertifiedFragment
  }
 }
`;